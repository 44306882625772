#Header {
    --logo-icons-input-height: 60%;
}

.Header-Container {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0 !important;
}

.H-Shadow {
    box-shadow: 0 8px 10px 0 rgb(0 0 0 / 10%);
}

#Header {
    width: 100%;
    height: var(--header-height);
    background-color: var(--green);
    display: flex;
    justify-content: center;
}

.h-center {
    width: 90%;
    height: 100%;
    display: flex;
    align-self: center;
}

.h-c-l-img-wrap {
    height: var(--header-height);
    width: 100%;

     
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
    text-decoration: none;

    position: relative;
}

.h-c-l-img {
    display: none;
}

.h-c-l-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.h-c-l-title h1 {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
}



.h-c-left,
.h-c-right {
    width: 25%;
}

.h-c-mid {
    width: 50%;
    height: 100%;

     
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-c-mid-form-wrap {
    width: 100%;
    height: var(--logo-icons-input-height);
    background-color: #fff;
    border-radius: 100px;
}

.h-c-mid-form-wrap:hover {
    box-shadow: 4px 4px 8px 0 rgb(0 0 0 / 10%);
}

.h-c-mid-form {
    width: 100%;
    height: 100%;

    display: flex;
}

.h-c-mid-input {
    width: 90%;
    height: 100%;
    background-color: transparent;
    text-indent: 24px;
}

.h-c-mid-input::placeholder {
    color: #c4c4c4;
    font-weight: bold;
}

.h-c-mid-serach-button {
    width: 70px;
    height: 100%;
    background-color: transparent;
    border-radius: 0 100px 100px 0;

    font-size: 18px;

     
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: -6px 0px 16px -4px #00000030;
}

.h-c-mid-serach-button:hover {
    background-color: var(--hover);
    color: white;
}

@media only screen and (max-width: 1170px) {
    .h-c-mid {
        width: 40%;
    }

    .h-c-left,
    .h-c-right {
        width: 30%;
    }
}

@media only screen and (max-width: 960px) {
    .h-c-l-img {
        margin: 0 8px 0 -4px;
        display: block;
        width: 30px;
        height: 30px;
    }

    .h-c-right,
    .h-c-l-title {
        display: none;
    }

    .h-c-left {
        width: fit-content;
    }


    .h-center {
        justify-content: space-between;
    }



    .h-c-mid {
        width: 94%;
    }

    .h-c-mid-input {
        width: 100%;
    }

    .h-c-mid-serach-button {
        width: 20%;
        box-shadow: 0px 0px 0px 0px transparent;
        color: #999999;
    }

    .h-c-mid-form-wrap:hover {
        box-shadow: 0px 0px 0px 0 rgb(0 0 0 / 0%);
    }
}

@media only screen and (max-width: 720px) {
    .h-c-mid {
        width: 92%;
    }
}

@media only screen and (max-width: 500px) {
    .h-c-mid {
        width: 90%;
    }
}

@media only screen and (max-width: 400px) {
    .h-c-mid {
        width: 86%;
    }
}